import * as React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RichText} from "prismic-reactjs";
import Button from "react-bootstrap/Button";
import GatsbyLink from "../components/gatsby-link";
import {useState} from "react";
import {isValidEmail} from "../utils/validation";
import Modal from "react-bootstrap/Modal";

const KontaktPage = ({ data }) => {
  const content = data.prismicContact.data;

  const [state, setState] = useState({
    form: {
      name: "",
      email: "",
      message: ""
    },
    modal: {
      title: "",
      message: {
        raw: []
      },
      hidden: true,
      button: ""
    }
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = (event) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...state.form })
    })
      .then(response => {
        if (response.ok) {
          return true;
        } else {
          // return true; // for testing success message uncomment this line
          throw response.statusText;
        }
      })
      .then(() => {
        setState({
          modal: {
            hidden: false,
            title: content.modal_success_title,
            message: content.modal_success_message,
            button: content.modal_success_button
          },
          form: {
            name: "",
            email: "",
            message: ""
          }
        });
      })
      .catch(err => setState({
        ...state,
        modal: {
          hidden: false,
          title: content.modal_error_title,
          message: content.modal_error_message,
          button: content.modal_error_button
        },
      }))

    event.preventDefault();
  }

  const handleDismiss = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        hidden: true
      }
    });
  }

  const handleChange = (event) => {
    setState({
        ...state,
        form: {
          ...state.form,
          [event.target.name]: event.target.value }
      }
    );
  }

  const isFormValid = () => {
    return isValidEmail(state.form.email) && state.form.name && state.form.message;
  }

  return (
    <>
      <Layout title={RichText.asText(content.titel.raw)}>
        <section>
          <Row>
            <Col>
              <h1>
                {RichText.asText(content.titel.raw)}
              </h1>
              <RichText render={content.beschreibung.raw} serializeHyperlink={GatsbyLink}/>
            </Col>
          </Row>
          <form onSubmit={handleSubmit}
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field">
            <Row>
              <Col md={6} className="mb-3 mb-md-0">
                <input className="w-100" name="name" inputMode="text" placeholder={content.input_name} required
                       value={state.form.name} onChange={handleChange}/>
              </Col>
              <Col md={6}>
                <input className="w-100" name="email" inputMode="email" placeholder={content.input_email} required
                       value={state.form.email} onChange={handleChange}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col xs={12}>
              <textarea className="w-100" name="message" inputMode="text" placeholder={content.input_message} required
                        value={state.form.message} onChange={handleChange}/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <Button type="submit" disabled={!isFormValid()}>{content.button}</Button>
              </Col>
            </Row>
          </form>
        </section>
        <section>
          <Row>
            <Col className="small">
              <RichText render={content.impressum.raw} serializeHyperlink={GatsbyLink}/>
            </Col>
          </Row>
        </section>
      </Layout>

      <Modal show={!state.modal.hidden} onHide={handleDismiss}>
        <Modal.Header className="justify-content-center">
          <Modal.Title className="text-center">{state.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <RichText render={state.modal.message.raw} serializeHyperlink={GatsbyLink}/>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary btn-sm" onClick={handleDismiss}>
            {state.modal.button}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const query = graphql`
query KontaktPageQuery {
  prismicContact {
    data {
      beschreibung {
        raw
      }
      button
      impressum {
        raw
      }
      input_email
      input_message
      input_name
      titel {
        raw
      }
      modal_success_title
      modal_success_message {
        raw
      }
      modal_success_button
      modal_error_title
      modal_error_message {
        raw
      }
      modal_error_button
    }
  }
}
`

export default KontaktPage
